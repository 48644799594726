<template>
  <div class="graph-section bg-neutral-25">
    <!-- SELECTED KPIS AND GRAPH TYPE SELECTOR -->
    <div class="w-full flex items-center gap-1.5 p-2">
      <!-- Selected KPIs list -->
      <div v-for="(kpi, index) in selectedKpis" :key="`selected-kpi-${index}`" class="selected-kpi">
        <!-- color indicator -->
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect x="7" y="7" width="10" height="10" rx="2" :fill="getKpiColor(index)" />
        </svg>
        <!-- KPI name -->
        <BaseText type="label" size="sm" class="text-text-muted capitalize">
          {{ getMetricName(kpi) }}
        </BaseText>
        <!-- Remove button -->
        <button class="group w-6 h-6 flex items-center justify-center"
        @click="removeMetric(kpi)">
          <TagRemoveIcon :width="16" class="text-text-subdued transition-colors duration-100 group-hover:text-icon-muted" />
        </button>
      </div>
      <!-- Add Metric Button -->
      <button class="group flex items-center p-0.5 rounded-md transition-colors duration-100"
      :class="canAddKpis ? 'hover:bg-neutral-50 text-text-muted' : 'text-text-disabled cursor-default'"
      :disabled="!canAddKpis">
        <div class="w-6 h-6 flex items-center justify-center">
          <PlusIcon :width="16" class="transition-colors duration-100"
          :class="canAddKpis ? 'text-icon-normal group-hover:text-icon-muted' : 'text-icon-disabled'" />
        </div>
        <BaseText type="label" size="sm" class="pl-0.5 py-0.5 pr-2">
          Add Metric
        </BaseText>
      </button>
      <!-- Graph Type Selector -->
      <div class="ml-auto flex items-center gap-0.5 p-0.5 rounded-md bg-neutral-50">
        <button class="graph-selector-atom" :class="{'active': graphType === 'bar'}" @click="changeGraphType('bar')">
          <BarGraphIcon class="text-icon-normal" />
        </button>
        <button class="graph-selector-atom" :class="{'active': graphType === 'line'}" @click="changeGraphType('line')">
          <LineGraphIcon class="text-icon-normal" />
        </button>
        <button class="graph-selector-atom" :class="{'active': graphType === 'grid'}" @click="changeGraphType('grid')">
          <GridGraphIcon class="text-icon-normal" />
        </button>
      </div>
    </div>
    <!-- GRAPH AREA -->
    <div class="w-full h-full">
      <LensBarGraphs v-if="graphType === 'bar'" :data-items="dataItems" :selected-kpis="selectedKpis" />
      <!-- PLACEHOLDERS -->
      <div v-else-if="graphType === 'line'" class="w-full flex items-center" style="height: 360px">
        <BaseText type="label" size="sm" class="text-text-muted w-full text-center">
          Included in future release
        </BaseText>
      </div>
      <div v-else-if="graphType === 'grid'" class="w-full flex items-center" style="height: 360px">
        <BaseText type="label" size="sm" class="text-text-muted w-full text-center">
          Included in future release
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import kpiColors from '../../../utils/lens/selectedMetricColors'

// Components
import LensBarGraphs from './bar/LensBarGraphs.vue'

// Icons
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import PlusIcon from '../../globals/Icons/PlusIcon.vue'
import BarGraphIcon from '../../globals/Icons/LensIcons/graphs/BarGraphIcon.vue'
import LineGraphIcon from '../../globals/Icons/LensIcons/graphs/LineGraphIcon.vue'
import GridGraphIcon from '../../globals/Icons/LensIcons/graphs/GridGraphIcon.vue'

export default {
  name: 'LensReportGraph',
  components: {
    LensBarGraphs,
    TagRemoveIcon,
    PlusIcon,
    BarGraphIcon,
    LineGraphIcon,
    GridGraphIcon
  },
  props: {
    graphType: {
      type: String,
      default: 'bar'
    },
    dataItems: {
      type: Array,
      default: () => []
    },
    selectedKpis: {
      type: Array,
      default: () => []
    },
    canAddKpis: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup'])
  },
  methods: {
    changeGraphType (type) {
      this.$emit('update:graphType', type)
    },
    removeMetric (toRemove) {
      const updatedKpis = this.selectedKpis.filter(kpi => kpi !== toRemove)
      this.$emit('updateSelectedKpis', updatedKpis)
    },
    getKpiColor (index) {
      return kpiColors[index]
    },
    getMetricName (kpi) {
      return this.getMetricLookup?.[kpi]?.name || kpi
    }
  }
}
</script>

<style scoped>
.graph-section {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  padding: 4px;
}
.selected-kpi {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.04);
}
.graph-selector-atom {
  padding: 2px;
  border-radius: 4px;
  background-color: transparent;
  box-shadow: none;
  transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
}
.graph-selector-atom:hover {
  background-color: #F6F8FA; /* neutral-25 */
}
.graph-selector-atom.active, .graph-selector-atom.active:hover {
  background-color: white;
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08), 0px 0.25px 1.5px 0.75px rgba(6, 7, 16, 0.02);
}
</style>
