<template>
  <div class="w-full max-w-full flex p-3">
    <div class="flex-grow flex items-center flex-wrap gap-2">
      <BaseSingleDropdown
        :selected-obj="selectedGroupBy"
        :options="groupByOptions"
        :min-width="'168px'"
        :selected-label="(opt) => `Group By ${opt.name}`"
        :option-label="(opt) => opt.name"
        placeholder="Select a grouping"
        @change="(selected) => $emit('update:groupBy', {type: 'group_by', value: selected.id})"
      />
      <!-- Add Filter Button -->
      <div v-on-clickaway="() => {closeFilterCreation()}" class="relative">
        <button class="flex items-center p-1 rounded-md transition-colors duration-100"
        :class="isBuildingFilter ? 'bg-neutral-25' : 'bg-white hover:bg-neutral-25'"
        @click="toggleFilterCreation">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M11.9993 8.66699V12.0003M11.9993 12.0003V15.3337M11.9993 12.0003H8.66602M11.9993 12.0003H15.3327"
            stroke="#5E6678" stroke-width="1.33333" stroke-linecap="round"/>
          </svg>
          <BaseText type="label" size="sm" class="text-text-muted pl-0.5 py-0.5 pr-2">
            Add Filter
          </BaseText>
        </button>
        <!-- Filter Type Selector Dropdown -->
        <transition>
          <div v-if="showFilterSelector" class="filter-selector-dropdown">
            <FilterSelectDropdown
              @filterOptionSelected="handleFilterOptionSelected"
              @close="closeFilterCreation"
            />
          </div>
        </transition>
        <!-- Filter Query Builder -->
        <transition>
          <div v-if="builderInitFilterOption" class="filter-query-builder">
            <LensFilterQueryBuilder
              :init-filter-option="builderInitFilterOption"
              @createFilter="handleFilterCreated"
              @close="closeFilterCreation"
            />
          </div>
        </transition>
      </div>
      <!-- Applied Filters List -->
      <div v-for="(filter, index) in appliedFilters" :key="`applied-filter-${index}`" 
      class="relative" :ref="`applied-filter-${index}`"
      v-on-clickaway="() => { if (editingFilterIndex === index) editingFilterIndex = null }">
        <AppliedFilter 
          :filterConditions="filter"
          :isEditing="editingFilterIndex === index"
          @selected="toggleEditAppliedFilter(index)"
          @remove="handleFilterRemoved(index)"
        />
        <!-- Edit Filter Query Builder -->
        <transition>
          <div v-if="editingFilterIndex === index" class="edit-filter-query-builder"
          :class="getEditFilterDropdownPositionClasses(index)">
            <LensFilterQueryBuilder 
              :initFilterConditions="filter"
              @createFilter="handleEditAppliedFilter($event, index)"
              @close="editingFilterIndex = null"
            />
          </div>
        </transition>
      </div>
    </div>
    <!-- Date Range Selector -->
    <div class="flex items-start flex-shrink-0">
      <div class="relative" v-on-clickaway="() => { showDateRangeSelector = false }">
        <button class="flex items-center gap-1.5 pl-2 py-1.5 pr-2.5 rounded-md flex-shrink-0"
        :class="showDateRangeSelector ? 'bg-neutral-25' : 'bg-white hover:bg-neutral-25'"
        @click="showDateRangeSelector = !showDateRangeSelector">
          <CalendarIcon class="text-icon-normal" />
          <BaseText type="label" size="sm" class="text-text-muted">
            {{ dateFilterLabel }}
          </BaseText>
        </button>
        <transition>
          <div v-if="showDateRangeSelector" class="date-selector-dropdown">
            <DateRangeSelectorDropdown 
              :selectedDateRange="selectedDateRange"
              :isLensReport="true"
              @update:selectedDateRange="$emit('update:selectedDateRange', $event)"
              @close="showDateRangeSelector = false"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import { getMatchingPresetName } from '../../../utils/dateUtils'
import FilterSelectDropdown from './FilterSelectDropdown.vue'
import LensFilterQueryBuilder from './LensFilterQueryBuilder.vue'
import AppliedFilter from './AppliedFilter.vue'
import DateRangeSelectorDropdown from '../../search/TopBarComponents/DateRangeSelectorDropdown.vue'

import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'
import CalendarIcon from '../../globals/Icons/CalendarIcon.vue'
import BaseSingleDropdown from '../../globals/BaseSingleDropdown.vue'

export default {
  name: 'LensFilterToolbar',
  mixins: [clickaway],
  components: {
    FilterSelectDropdown,
    LensFilterQueryBuilder,
    AppliedFilter,
    DateRangeSelectorDropdown,
    ChevronIcon,
    CalendarIcon,
    BaseSingleDropdown
  },
  props: {
    appliedFilters: {
      type: Array,
      required: true
    },
    groupBy: {
      type: String,
      default: () => 'ad_name'
    },
    selectedDateRange: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const groupByOptions = [
      { id: 'ad_name', name: 'Ad Name' },
      { id: 'ad_copy', name: 'Copy' },
      { id: 'creative', name: 'Creative' },
      { id: 'destination_url', name: 'Landing Page' },
      { id: 'ad_title', name: 'Headline' }
    ]
    return {
      builderInitFilterOption: null,
      groupByOptions,
      dateFilterLabel: 'All Time',
      showFilterSelector: false,
      showDateRangeSelector: false,
      editingFilterIndex: null
    }
  },
  watch: {
    selectedDateRange (newVal) {
      if (!newVal?.start || !newVal?.end) {
        this.dateFilterLabel = 'All Time'
      } else {
        this.dateFilterLabel = getMatchingPresetName(newVal) 
          || formatCustomDateRangeLabel(newVal.start, newVal.end)
      }
    }
  },
  computed: {
    selectedGroupBy () {
      return this.groupByOptions.find((opt) => opt.id === this.groupBy)
    },
    isBuildingFilter () {
      return this.showFilterSelector || this.builderInitFilterOption
    }
  },
  methods: {
    handleFilterCreated (filterConditions) {
      const newFilters = [...this.appliedFilters, filterConditions]
      this.$emit('update:appliedFilters', newFilters)
    },
    handleFilterRemoved (index) {
      const newFilters = this.appliedFilters.filter((_, i) => i !== index)
      this.$emit('update:appliedFilters', newFilters)
    },
    handleFilterOptionSelected (option) {
      this.builderInitFilterOption = option
      this.showFilterSelector = false
    },
    handleEditAppliedFilter (filterConditions, index) {
      const newFilters = [...this.appliedFilters]
      newFilters[index] = filterConditions
      this.$emit('update:appliedFilters', newFilters)
    },
    toggleFilterCreation () {
      if (this.isBuildingFilter) {
        this.closeFilterCreation()
      } else {
        this.showFilterSelector = true
      }
    },
    closeFilterCreation () {
      this.showFilterSelector = false
      this.builderInitFilterOption = null
    },
    toggleEditAppliedFilter (index) {
      if (this.editingFilterIndex !== index) {
        this.editingFilterIndex = index
      } else {
        this.editingFilterIndex = null
      }
    },
    getEditFilterDropdownPositionClasses (index) {
      // Determines whether to left-align, center, or right-align the edit filter dropdown
      const filterElement = this.$refs[`applied-filter-${index}`][0]
      const mainLayoutParent = document.querySelector('.main-layout')
      if (!filterElement || !mainLayoutParent) return 'left-0'

      const mainLayoutRect = mainLayoutParent.getBoundingClientRect()
      const filterElmRect = filterElement.getBoundingClientRect()
      const filterElmPos = (filterElmRect.left - mainLayoutRect.left) + (filterElmRect.width / 2)
      
      const sectorWidth = mainLayoutRect.width / 3
      const sectorBoundaries = [sectorWidth, sectorWidth * 2]

      if (filterElmPos < sectorBoundaries[0]) {
        return 'left-0'
      } else if (filterElmPos < sectorBoundaries[1]) {
        return 'left-1/2 transform -translate-x-1/2'
      } else {
        return 'right-0'
      }
    }
  }
}

const formatCustomDateRangeLabel = (startDate, endDate) => {
    // Check if the start and end dates are in the same year.
    if (startDate.getFullYear() === endDate.getFullYear()) {
    // Format: "Jan 27 - Feb 9, 2025"
    const startStr = startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    const endStr = endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    return `${startStr} - ${endStr}`;
  } else {
    // Format: "Jan 27, 2024 - Feb 9, 2025"
    const startStr = startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const endStr = endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    return `${startStr} - ${endStr}`;
  }
}
</script>

<style scoped>
.group-by-selector {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.filter-selector-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
}
.filter-query-builder {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
}
.edit-filter-query-builder {
  position: absolute;
  top: calc(100% + 4px);
}
.date-selector-dropdown {
  position: absolute;
  top: calc(100% + 6px);
  right: 0;
  z-index: 500;
}

.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}

.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>
