<template>
  <div class="dropdown-container flex flex-col pl-1 py-1 pr-0.5 rounded-xl">
    <!-- Search Bar -->
    <div class="w-full flex items-center gap-2 px-2 py-1.5">
      <SearchIcon className="text-icon-normal flex-shrink-0" />
      <input v-model="searchQuery" ref="searchInput" class="filter-search-input flex-grow" placeholder="Search" 
      @keydown.enter.prevent @keydown.up.prevent @keydown.down.prevent @keydown.escape.prevent @keydown.tab.prevent/>
    </div>
    <div class="w-full flex items-center px-2 pt-1">
      <div class="w-full h-0 border-t border-border-normal" />
    </div>
    <!-- Static Filter Options -->
    <div ref="optionsContainer" class="options-container flex flex-col pr-0.5" :style="{ maxHeight: `${optionsMaxHeight}px` }">
      <!-- No results state -->
      <div v-if="!filterCategories.length" class="w-full px-2 py-1.5">
        <BaseText type="body" size="sm" weight="light" class="text-text-disabled">
          No matching filters found
        </BaseText>
      </div>
      <!-- Categories & Options List -->
      <div v-for="(category, index) in filterCategories" :key="`category-${category.categoryName}`"
      class="w-full flex flex-col">
        <!-- divider -->
        <div v-if="index > 0" class="w-full flex items-center px-2 pt-1">
          <div class="w-full h-0 border-t border-border-normal" />
        </div>
        <!-- Category Header -->
        <button class="category-header w-full pt-1 focus:outline-none" :ref="`category-${category.categoryName}`" tabindex="-1"
        @mouseenter="navIndex = null" @click="toggleCategoryExpansion(category.categoryName)">
          <!-- fade overlay -->
          <div v-if="isCategoryExpanded(category.categoryName)" class="category-header-fade" />
          <!-- header content -->
          <div class="w-full flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors duration-100"
          :class="currentNavItem?.ref === `category-${category.categoryName}` ? 'bg-neutral-25' : 'hover:bg-neutral-25'">
            <component :is="category.icon" class="text-icon-muted flex-shrink-0" />
            <BaseText type="label" size="sm" class="text-text-muted flex-grow text-left">
              {{ category.categoryName }}
            </BaseText>
            <div class="flex-shrink-0" :class="{ 'transition-transform': doAnimExpansions }"
            :style="{ transform: isCategoryExpanded(category.categoryName) ? 'scaleY(-1)' : 'scaleY(1)' }">
              <ChevronIcon class="text-icon-muted" />
            </div>
          </div>
        </button>
        <!-- Category Options -->
        <div class="w-full flex flex-col gap-0.5 overflow-hidden min-w-0" :class="{ 'transition-height': doAnimExpansions }"
        :style="{ height: isCategoryExpanded(category.categoryName) ? `${getCategoryOptionsHeight(index)}px` : '0px' }">
          <button 
            v-for="(option, index) in category.options" :key="`${category.categoryName}-${option.name}`"
            :ref="`option-${category.categoryName}-${option.key}`" tabindex="-1"
            class="w-full px-2 py-1.5 rounded-md transition-colors duration-100 text-text-normal focus:outline-none"
            :class="[
              currentNavItem?.ref === `option-${category.categoryName}-${option.key}` ? 'bg-neutral-25 text-text-muted' : 'hover:bg-neutral-25 hover:text-text-muted', 
              {'mt-0.5': index === 0, 'mb-0.5': index === category.options.length - 1}
            ]"
            @mouseenter="navIndex = null"
            @click="selectFilterOption(option)"
          >
            <BaseText type="body" size="sm" class="text-left w-full truncate">
              {{ option.name }}
            </BaseText>
          </button>
        </div>
      </div>
    </div>
    <!-- bottom fade overlay -->
    <transition name="fade-overlay">
      <div v-if="listOverflows && !isMaxScroll" class="bottom-fade-overlay" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import staticFilterOptions from '../../../utils/lens/filters/staticFilterOptions'
import dynamicFilterOptions from '../../../utils/lens/filters/dynamicFilterOptions'

import SearchIcon from '../../globals/Icons/SearchIcon.vue'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'

const DEFAULT_OPTIONS_MAX_HEIGHT = 365
const SEARCH_RESULTS_LIMIT = 6

export default {
  name: 'FilterSelectDropdown',
  components: {
    SearchIcon,
    ChevronIcon
  },
  data () {
    return {
      searchQuery: '',

      // UI States
      expandedCategories: [],
      navIndex: null,
      forceCategoriesExpanded: false,
      doAnimExpansions: true,
      listOverflows: false,
      isMaxScroll: false,
      optionsMaxHeight: DEFAULT_OPTIONS_MAX_HEIGHT
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs?.searchInput?.focus()
      this.$refs?.optionsContainer?.addEventListener('scroll', this.handleScroll)
      this.computeOptionsMaxHeight()
      window.addEventListener('resize', this.computeOptionsMaxHeight)
      window.addEventListener('keydown', this.handleKeyboardNavigation)
    })
  },
  beforeDestroy () {
    this.$refs?.optionsContainer?.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.computeOptionsMaxHeight)
    window.removeEventListener('keydown', this.handleKeyboardNavigation)
  },
  watch: {
    searchQuery (newVal) {
      if (newVal.length) {
        // Expand all categories
        this.expandedCategories = this.filterCategories.map(category => category.categoryName)
      } else {
        // Collapse all categories
        this.expandedCategories = []
      }
      this.handleScroll()
      // Temporarily disable height animation
      this.doAnimExpansions = false
      setTimeout(() => { this.enableExpansionTransitions() }, 75)
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetrics']),
    filterCategories () {
      const categories = [...staticFilterOptions, ...dynamicFilterOptions()]

      // If there is a search query, we need to filter and sort the options
      if (this.searchQuery.length) {
        let optionResults = []
        categories.forEach(category => {
          const matchingOptions = category.options.filter(option => option.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
          optionResults.push(...matchingOptions.map(option => ({category, option})))
        })

        // Sort by the closeness of the match (closer = lower index)
        optionResults.sort((a, b) => {
          const ratioA = this.searchQuery.length / a.option.name.length
          const ratioB = this.searchQuery.length / b.option.name.length
          return ratioB - ratioA
        })
        optionResults = optionResults.slice(0, SEARCH_RESULTS_LIMIT)

        // Put the results back into their category structure
        const processedCategories = []
        const categoryResults = []
        optionResults.forEach(option => {
          if (!processedCategories.includes(option.category.categoryName)) {
            processedCategories.push(option.category.categoryName)
            const category = {
              ...option.category,
              options: optionResults.filter(o => o.category.categoryName === option.category.categoryName).map(o => o.option)
            }
            categoryResults.push(category)
          }
        })

        return categoryResults
      }

      // If there is no search query, we can just return the categories as is
      return categories
    },
    navigationItems () {
      const navItems = []
      this.filterCategories.forEach(category => {
        navItems.push({
          ref: `category-${category.categoryName}`,
          action: () => { this.toggleCategoryExpansion(category.categoryName) }
        })
        if (this.expandedCategories.includes(category.categoryName)) {
          category.options.forEach(option => {
            navItems.push({
              ref: `option-${category.categoryName}-${option.key}`,
              action: () => { this.selectFilterOption(option) }
            })
          })
        }
      })
      return navItems
    },
    currentNavItem () {
      return this.navIndex !== null ? this.navigationItems[this.navIndex] : null
    }
  },
  methods: {
    selectFilterOption (option) {
      this.$emit('filterOptionSelected', option)
    },

    // ================================= UI Methods =================================

    toggleCategoryExpansion (categoryName) {
      if (this.isCategoryExpanded(categoryName)) {
        this.expandedCategories = this.expandedCategories.filter(name => name !== categoryName)
      } else {
        this.expandedCategories.push(categoryName)
      }
    },
    isCategoryExpanded (categoryName) {
      return this.expandedCategories.includes(categoryName)
    },
    getCategoryOptionsHeight (index) {
      const category = this.filterCategories[index]
      const numOptions = category.options.length
      return numOptions * 32 + (numOptions - 1) * 2 + 4
    },
    enableExpansionTransitions: _.debounce(function () {
      this.doAnimExpansions = true
    }, 75),
    handleScroll (event = null) {
      const scroller = event?.target ?? this.$refs.optionsContainer
      if (!scroller) return

      this.listOverflows = scroller.scrollHeight > scroller.clientHeight
      this.isMaxScroll = Math.abs(scroller.clientHeight - (scroller.scrollHeight - Math.ceil(scroller.scrollTop))) <= 2
    },
    computeOptionsMaxHeight () {
      const viewportHeight = window.innerHeight
      const optionsContainerTop = this.$refs.optionsContainer.getBoundingClientRect().top
      this.optionsMaxHeight = viewportHeight - optionsContainerTop - 32 // -32px for margin
    },

    // ================================= Navigation Methods =================================

    handleKeyboardNavigation (event) {
      const validKeys = ['ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Tab']
      if (!validKeys.includes(event.key)) return
      event.preventDefault()
      switch (event.key) {
        case 'ArrowDown':
          this.navigateNext(); break
        case 'ArrowUp':
          this.navigatePrev(); break
        case 'Enter':
          this.selectNavItem(); break
        case 'Escape':
          this.$emit('close'); break
      }
    },
    navigateNext () {
      if (this.navIndex === null || this.navIndex < this.navigationItems.length - 1) {
        this.navIndex = this.navIndex === null ? 0 : this.navIndex + 1
      }
    },
    navigatePrev () {
      if (this.navIndex === 0) {
        this.navIndex = null

      } else if (this.navIndex !== null) {
        this.navIndex--
      }
    },
    selectNavItem () {
      if (this.currentNavItem) this.currentNavItem.action()
    },
  }
}
</script>

<style scoped>
.dropdown-container {
  position: relative;
  width: 250px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.options-container {
  overflow-y: auto;
}
.options-container::-webkit-scrollbar {
  width: 3px;
}
.options-container::-webkit-scrollbar-thumb {
  background-color: #DFE1E7;
  border-radius: 18px;
}
.category-header {
  position: sticky;
  top: 0;
}
.category-header-fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0px;
  z-index: -1;
  background: linear-gradient(to bottom, white 0%, white 85%, transparent 100%);
  animation: delayEnter 1ms 20ms forwards;
}
@keyframes delayEnter {
  0% { height: 0px; }
  100% { height: 40px; }
}
.transition-height {
  transition: height 150ms ease-in-out;
}
.bottom-fade-overlay {
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 3px;
  height: 6px;
  z-index: 10;
  pointer-events: none;
  background: linear-gradient(to top, white, transparent);
}

/* ====== Search Input Styles ====== */

.filter-search-input {
  /* Reset default styles */
  -webkit-appearance: none;  /* Remove default styling in WebKit browsers */
  -moz-appearance: none;     /* Remove default styling in Firefox */
  appearance: none;          /* Remove default styling in modern browsers */
  padding: 0;
  margin: 0;
  border: none;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  outline: none;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.filter-search-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.filter-search-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.filter-search-input:hover::placeholder {
  color: #303546;
}
.filter-search-input:focus::placeholder {
  color: #A4ACB9;
}

/* ========= Vue <transition> classes ========= */
.fade-overlay-enter-active, .fade-overlay-leave-active {
  transition: opacity 75ms ease-in-out, transform 75ms ease-in-out;
}
.fade-overlay-enter-from, .fade-overlay-enter, .fade-overlay-leave-to {
  opacity: 0;
  transform: scaleY(0);
}
.fade-overlay-enter-to, .fade-overlay-leave-from {
  opacity: 1;
  transform: scaleY(1);
}
</style>