<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M2.75 14.2498V5.74984C2.75 4.09298 4.09315 2.74984 5.75 2.74984H10M2.75 14.2498C2.75 15.9067 4.09315 17.2498 5.75 17.2498H14.25C15.9069 17.2498 17.25 15.9067 17.25 14.2498M2.75 14.2498V13.7498M17.25 14.2498V13.7498M17.25 14.2498V9.99984" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.7893 1.57879C15.6358 1.27169 15.1975 1.27169 15.044 1.57879L14.2287 3.20922C14.1885 3.28985 14.123 3.35524 14.0424 3.39555L12.412 4.21076C12.1049 4.36431 12.1049 4.80257 12.412 4.95612L14.0424 5.77133C14.123 5.81164 14.1885 5.87703 14.2287 5.95767L15.044 7.58809C15.1975 7.89519 15.6358 7.89519 15.7893 7.58809L16.6045 5.95767C16.6448 5.87703 16.7102 5.81164 16.7909 5.77133L18.4213 4.95612C18.7284 4.80257 18.7284 4.36431 18.4213 4.21076L16.7909 3.39555C16.7102 3.35524 16.6448 3.28985 16.6045 3.20922L15.7893 1.57879Z" 
    fill="currentColor"/>
    <path d="M6.5 10.5417V13.9167M9.875 6.4917V13.9167M13.25 11.8917V13.9167" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'CreativeInsightsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
