<template>
  <button class="flex items-center gap-1 pl-2.5 py-1 pr-1 rounded-md transition-colors duration-100"
  :class="isEditing ? 'bg-neutral-50' : 'bg-neutral-25 hover:bg-neutral-50'"
  @click="$emit('selected')">
    <div v-for="(condition, index) in filterConditions" :key="condition.renderKey" class="flex items-center">
      <BaseText type="label" size="sm" class="text-text-muted mr-1">
        {{ condition.property.name }}
      </BaseText>
      <BaseText type="body" size="sm" class="text-text-normal mr-1">
        {{ condition.operation.name }}
      </BaseText>
      <BaseText type="label" size="sm" class="text-text-muted">
        {{ formatConditionValue(condition.value, condition.property?.input?.type || 'text') }}
      </BaseText>
      <BaseText v-if="index < filterConditions.length - 1" type="body" size="sm" class="text-text-normal mr-0.5">
        ,
      </BaseText>
    </div>
    <button class="w-6 h-6 flex items-center justify-center transition-colors duration-100 hover:bg-neutral-25"
    style="border-radius: 4px;" @click.stop="$emit('remove')">
      <TagRemoveIcon class="text-icon-normal" :width="18" :height="18" />
    </button>
  </button>
</template>

<script>
import { format } from 'd3-format'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'AppliedFilter',
  components: {
    TagRemoveIcon
  },
  props: {
    filterConditions: {
      type: Array,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatConditionValue(value, type) {
      if (type === 'currency') {
        const hasDecimal = value.toString().includes('.')
        const fixedValue = value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: hasDecimal ? 2 : 0
        })
        return `$${fixedValue}`
      } else if (type === 'number') {
        return value.toLocaleString()
      } else if (type === 'percent') {
        const formatPercent = format('~%')
        return formatPercent(value)
      } else if (type === 'text') {
        return `"${value}"`
      }
      return value
    }
  }
}

</script>