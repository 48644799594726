<template>
  <div class="flex items-center gap-1 h-6">
    <BaseText
      v-for="(part, index) in formulaParts"
      :key="index"
      type="heading"
      size="overline"
      class="px-2 py-1 rounded-md text-center"
      :class="`formula-${part.type}`"
    >
      {{ part.value }}
    </BaseText>
  </div>
</template>

<script>
export default {
  name: 'MetricFormulaRenderer',
  props: {
    ast: {
      type: Object,
      required: true
    }
  },
  computed: {
    formulaParts () {
      const parts = []
      this.traverseAST(this.ast, parts)
      return parts
    }
  },
  methods: {
    traverseAST (node, parts) {
      if (node.type === 'binOp') {
        this.traverseAST(node.left, parts)
        parts.push({ type: 'operation', value: node.operation })
        this.traverseAST(node.right, parts)
      } else if (node.type === 'literal') {
        parts.push({ type: 'literal', value: node.value })
      }
    }
  }
}
</script>

<style scoped>

.formula-literal{
    color: rgba(60, 148, 249, 1);
    background-color: rgba(60, 148, 249, 0.06);
    width: auto;
}

.formula-operation{
    color: rgba(137,113,234,1);
    background-color: rgba(137,113,234,0.06);
    width: 24px;
}
</style>
