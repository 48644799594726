<template>
  <!-- Manage Board Modal -->
  <BaseModal
    no-padding
    no-toolbar-padding
    no-exit
    card-width="480px"
    @close="$emit('close')"
  >
    <template #toolbar>
      <div class="flex flex-col relative">
        <div
          v-if="isNew"
          class="pt-1 flex items-center gap-2"
        >
          <SaveIcon class="w-6 h-6 text-neutral-500" />
          <BaseText
            type="label"
            class="text-text-muted"
          >
            Save New Preset
          </BaseText>
        </div>
        <div
          v-else
          class="pt-1 flex items-center gap-2"
        >
          <SettingsIcon class="w-6 h-6 text-neutral-500" />
          <BaseText
            type="label"
            class="text-text-muted"
          >
            Edit Preset
          </BaseText>
        </div>
      </div>
      <button
        class="absolute top-3 right-3 p-1.5"
        @click="$emit('close')"
      >
        <TagRemoveIcon stroke="#5E6678" />
      </button>
    </template>

    <template #default>
      <!-- Preset info -->
      <div
        v-if="!isNew"
        class="px-5 py-4"
      >
        <SelectedPresetDropdown
          disable-cta
          :selected-preset="presetForm"
          @handleSelect="updateSelectedPreset"
        />
      </div>
      <div
        v-else
        class="pt-5"
      />
      <div class="grid grid-cols-4 p-5 gap-5 border-t border-b border-neutral-50">
        <!-- Name -->
        <div class="flex flex-col gap-1 w-full col-span-4">
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Name
          </BaseText>

          <input
            v-model="presetForm.name"
            placeholder="Preset name"
            class="form-input fade-in min-w-0 w-full px-2 py-1.5"
          >
        </div>
        <div class="col-span-2 row-span-1">
          <BaseSingleDropdown
            :selected-obj="presetForm.attribution_model?.click"
            label="Attribution Window"
            :options="attributionWindowOptions"
            show-label-space
            :option-label="(opt) => opt.name"
            placeholder="Select a model"
            @change="updateForm('attribution_model', $event, 'click')"
          />
        </div>
        <div class="col-span-2 row-span-1">
          <BaseSingleDropdown
            :selected-obj="presetForm.attribution_model?.view"
            :options="viewOptions"
            show-label-space
            :option-label="(opt) => opt.name"
            placeholder="No View"
            @change="updateForm('attribution_model', $event, 'view')"
          />
        </div>
        <div class="col-span-4 row-span-1 flex items-center gap-3.5">
          <input
            type="checkbox"
            :checked="presetForm.is_default"
            class="h-4 w-4 rounded checkbox text-black cursor-pointer focus:ring-transparent"
            @change="presetForm.is_default = !presetForm.is_default"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Set as Default Preset
          </BaseText>
        </div>
      </div>

      <div class="flex justify-between items-center p-5">
        <button
          v-if="isNew"
          class="p-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
          @click="$emit('close')"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Cancel
          </BaseText>
        </button>
        <button
          v-else
          class="p-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
          @click="deletePreset"
        >
          <BaseText
            type="label"
            size="sm"
          >
            <div class="flex items-center gap-1.5">
              <DeleteIcon class="text-icon-normal" />
              <span class="text-text-muted">Delete</span>
            </div>
          </BaseText>
        </button>
        <button
          class="save-btn black-btn-primary transition-colors text-white bg-black px-3 py-1.5 rounded-md"
          :disabled="!canSavePreset"
          @click="handlePresetSave"
        >
          <BaseText
            type="label"
            size="sm"
          >
            Save
          </BaseText>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
// Components
import BaseSingleDropdown from '../../globals/BaseSingleDropdown.vue'
import SelectedPresetDropdown from '../SelectedPresetDropdown.vue'
// Icons
import SettingsIcon from '../../globals/Icons/SettingsIcons/SettingsIcon.vue'
import DeleteIcon from '../../globals/Icons/DeleteIcon.vue'
import SaveIcon from '../../globals/Icons/SaveIcon.vue'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
export default {
  name: 'EditPresetModal',
  components: {
    SettingsIcon,
    BaseSingleDropdown,
    DeleteIcon,
    SaveIcon,
    TagRemoveIcon,
    SelectedPresetDropdown
  },
  props: {
    isNew: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    const attributionWindowOptions = [
      { id: '0y', name: 'No Click' },
      { id: '1d', name: '1 Day Click' },
      { id: '7w', name: '7 Day Click' },
      { id: '1m', name: '28 Day Click' }

    ]

    const viewOptions = [
      { id: '0y', name: 'No View' },
      { id: '1d', name: '1 Day View' },
      { id: '1w', name: '7 Day View' },
      { id: '1m', name: '28 Day View' }

    ]
    return {
      // TODO @Sam: update with real data
      attributionWindowOptions,
      viewOptions,

      presetForm: {
        name: '',
        attribution_model: {
          click: '',
          view: ''
        },
        is_default: false
      }
    }
  },

  computed: {
    ...mapGetters('LensModule', ['getLocalPreset', 'getPresets']),
    localPreset () {
      return this.getLocalPreset
    },
    canSavePreset () {
      const isFormFilled = !!this.presetForm.name
      const localAttribution = this.getLocalPreset.attribution_modell
      const hasFormChanged = this.presetForm.name !== this.localPreset.name || this.presetForm?.attribution_model.click !== localAttribution?.click || this.presetForm.attribution_model?.view !== localAttribution?.view
      return isFormFilled && hasFormChanged
    }
  },
  watch: {
    localPreset: {
      handler (newPreset) {
      // Only update if selectedPreset changes
        if (!this.isNew && newPreset) {
          const click = this.attributionWindowOptions.find((opt) => opt.id === newPreset.attribution_model?.click)
          const view = this.viewOptions.find((opt) => opt.id === newPreset.attribution_model?.view)
          this.presetForm = {
            id: newPreset?.id,
            name: newPreset.name,
            attribution_model: {
              click,
              view
            },
            is_default: newPreset.is_defaultt
          }
        }
      },
      immediate: true // Initialize on mount as well
    }
  },
  methods: {
    ...mapActions('LensModule', ['setPresetById']),
    updateSelectedPreset (presetId) {
      const newPreset = this.getPresets.find((preset) => preset.id === presetId)
      this.presetForm = {
        ...newPreset
      }
      // this.setPresetById({id: presetId})
    },
    handlePresetSave () {
      const { click, view } = this.presetForm?.attribution_model
      const toSave = {
        ...this.presetForm,
        attribution_model: {
          click: click?.id,
          view: view?.id
        }
      }
      this.$emit('save', toSave)
      this.$emit('close')
    },
    deletePreset () {
      this.$emit('delete', this.presetForm.id)
      this.$emit('close')
    },
    updateForm (key, value, childKey) {
      if (childKey) {
        this.presetForm[key][childKey] = value
      } else {
        this.presetForm[key] = value
      }
    }
  }
}
</script>

<style scoped>

.form-input {
  color: #06070F;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

.save-btn:disabled {
  color: #a4abb8 !important;
  background-color: #F6F8FA !important;
  box-shadow: none;
}
</style>
