import { render, staticRenderFns } from "./ManagePresetHeader.vue?vue&type=template&id=13c5ac30&scoped=true"
import script from "./ManagePresetHeader.vue?vue&type=script&lang=js"
export * from "./ManagePresetHeader.vue?vue&type=script&lang=js"
import style0 from "./ManagePresetHeader.vue?vue&type=style&index=0&id=13c5ac30&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c5ac30",
  null
  
)

export default component.exports