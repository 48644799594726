<template>
  <div v-if="type === 'dropdown'" class="relative w-full" v-on-clickaway="() => { $emit('closeDropdown') }">
    <button class="w-full flex items-center gap-0.5 pl-2.5 py-1 pr-1 transition-colors rounded-r-md"
    :class="{ 'bg-neutral-25': showDropdown }"
    @click="$emit('toggleDropdown')">
      <BaseText type="label" size="sm" class="text-text-muted whitespace-nowrap">
        {{ dropdownSelection?.name || 'Select' }}
      </BaseText>
      <div class="ml-auto flex-shrink-0 transition-transform"
      :style="{ transform: showDropdown ? 'scaleY(-1)' : 'scaleY(1)' }">
        <ChevronIcon class="text-icon-normal" />
      </div>
    </button>
    <transition>
      <div v-if="showDropdown" class="value-dropdown flex flex-col p-1">
        <button v-for="(option, index) in dropdownOptions" :key="`dropdown-option-${index}`"
        class="option w-full flex items-center gap-2 p-1.5 rounded-md transition-colors duration-100
        text-neutral-alpha-650 hover:bg-neutral-alpha-50 hover:text-white"
        @click="selectDropdownOption(option)">
          <BaseText type="body" size="sm" class="whitespace-nowrap">
            {{ option.name }}
          </BaseText>
          <div class="w-5 h-5 ml-auto">
            <NewCheckmarkIcon v-if="dropdownSelection?.value === option.value" />
          </div>
        </button>
      </div>
    </transition>
  </div>
  <div v-else class="w-full flex items-center gap-1 px-2 py-1">
    <BaseText v-if="type === 'currency'" type="body" size="sm" class="text-text-muted">
      $
    </BaseText>
    <input
      :value="inputValue"
      :type="isNumberType ? 'number' : 'text'"
      :placeholder="placeholder"
      class="value-input w-full" 
      :style="{ minWidth: inputMinWidth }"
      @input="inputUpdateMethod"
      @blur="inputFormatMethod"
    />
    <BaseText v-if="type === 'percent'" type="body" size="sm" class="text-text-muted">
      %
    </BaseText>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'
import NewCheckmarkIcon from '../../globals/Icons/NewCheckmarkIcon.vue'

export default {
  name: 'ConditionValueInput',
  mixins: [clickaway],
  components: {
    ChevronIcon,
    NewCheckmarkIcon
  },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    dropdownOptions: {
      type: Array,
      default: () => []
    },
    showDropdown: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputValue: '',
      dropdownSelection: null,
    }
  },
  created () {
    if (this.type === 'dropdown') {
      this.dropdownSelection = this.dropdownOptions.find(option => option.value === this.value)
    } else {
      this.inputValue = `${this.value}`
      this.inputFormatMethod()
    }
  },
  watch: {
    value (newValue) {
      if (!newValue) this.inputValue = ''
    },
    dropdownOptions (newOptions) {
      if (this.type === 'dropdown') {
        this.setDefaultDropdownSelection(newOptions)
      }
    }
  },
  computed: {
    isNumberType () {
      return ['number', 'currency', 'percent'].includes(this.type)
    },
    inputUpdateMethod () {
      return this.isNumberType ? this.handleNumberInput : this.handleTextInput
    },
    inputFormatMethod () {
      return this.type === 'currency' ? this.formatCurrencyInput : () => {}
    },
    inputMinWidth () {
      if (this.type === 'percent') return '40px'
      else if (['currency', 'number'].includes(this.type)) return '60px'
      return '100px'
    }
  },
  methods: {
    setDefaultDropdownSelection (options = null) {
      const initOption = (options ? options[0] : this.dropdownOptions[0]) || null
      this.dropdownSelection = initOption
      this.$emit('updateValue', initOption.value)
    },
    // Input Methods
    handleTextInput (event) {
      this.$emit('updateValue', event.target.value)
      this.inputValue = event.target.value
    },
    handleNumberInput (event) {
      const rawString = event.target.value
      if (!rawString.length) {
        this.$emit('updateValue', null)
        this.inputValue = ''
        return
      }
      const parsedValue = parseFloat(rawString)
      if (isNaN(parsedValue)) return
      this.$emit('updateValue', this.type === 'percent' ? parsedValue / 100 : parsedValue)
      this.inputValue = rawString
    },
    formatCurrencyInput () {
      // Due to the number input handler, we assume the string parses to a number
      const numericValue = parseFloat(this.inputValue)
      if (isNaN(numericValue)) return
      const formattedValue = numericValue.toFixed(2)
      this.inputValue = formattedValue
    },
    // Dropdown Methods
    selectDropdownOption (option) {
      this.$emit('updateValue', option.value)
      this.dropdownSelection = option
      this.$emit('closeDropdown')
    }
  }
}

</script>

<style scoped>
.value-input {
  /* Reset default styles */
  -webkit-appearance: none;  /* Remove default styling in WebKit browsers */
  -moz-appearance: none;     /* Remove default styling in Firefox */
  appearance: none;          /* Remove default styling in modern browsers */
  margin: 0;
  padding: 0;
  border: none;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  outline: none;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.value-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.value-input::placeholder {
  opacity: 0.5;
  transition: opacity 100ms ease-in-out;
}
.value-input:focus::placeholder {
  opacity: 0.3;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.value-dropdown {
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background-color: rgba(6, 7, 16, 0.92);
  backdrop-filter: blur(40px);
  z-index: 100;
}
.option {
  width: auto; /* fallback for calc-size */
  /* width: calc-size(auto, round(up, size, 2px)); */
}

/* ========= Vue <transition> classes ========= */

.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>
