import CampaignStructureIcon from '../../../components/globals/Icons/LensIcons/filters/CampaignStructureIcon.vue'
import SetupStatusIcon from '../../../components/globals/Icons/LensIcons/filters/SetupStatusIcon.vue'
import AdContentsIcon from '../../../components/globals/Icons/LensIcons/filters/AdContentsIcon.vue'

import getFilterTypeOperations from './filterTypeOperations'

export default [
  {
    categoryName: 'Campaign Structure',
    icon: CampaignStructureIcon,
    options: [
      {
        name: 'Campaign Name',
        key: 'campaign_name',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Name' }
      },
      {
        name: 'Ad Set Name',
        key: 'adset_name',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Name' }
      },
      {
        name: 'Ad Name',
        key: 'ad_name',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Name' }
      },
    ]
  },
  {
    categoryName: 'Setup & Status',
    icon: SetupStatusIcon,
    options: [
      // TODO: UPDATE WHEN BACKEND SUPPORTS THE BELOW OPTIONS
      {
        name: 'Ad Status',
        key: 'ad_status',
        validOperations: getFilterTypeOperations('dropdown'),
        input: { 
          type: 'dropdown', 
          options: [
            { value: 'active', name: 'Active' },
            { value: 'paused', name: 'Paused' },
          ]
        }
      },
      {
        name: 'Distribution Format',
        key: 'distribution_format',
        validOperations: getFilterTypeOperations('dropdown'),
        input: { 
          type: 'dropdown', 
          options: [
            { value: 'todo', name: 'TODO'}
          ]
        }
      }
    ]
  },
  {
    categoryName: 'Ad Contents',
    icon: AdContentsIcon,
    options: [
      {
        name: 'Ad Type',
        key: 'ad_format',
        validOperations: getFilterTypeOperations('dropdown'),
        input: { 
          type: 'dropdown', 
          // TODO: This might need to be updated
          options: [
            { value: 'image', name: 'Image' },
            { value: 'video', name: 'Video' },
            { value: 'carousel', name: 'Carousel' }
          ] 
        }
      },
      // TODO: UPDATE WHEN BACKEND SUPPORTS THE BELOW OPTIONS
      {
        name: 'Landing Page',
        key: 'landing_page',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'URL' }
      },
      {
        name: 'Copy',
        key: 'ad_copy',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Copy' }
      },
      {
        name: 'CTA Type',
        key: 'cta_type',
        validOperations: getFilterTypeOperations('dropdown'),
        input: { 
          type: 'dropdown', 
          options: [
            // Probably not correct:
            { value: 'learn_more', name: 'Learn More' },
            { value: 'shop_now', name: 'Shop Now' },
            { value: 'book_now', name: 'Book Now' },
            { value: 'call_now', name: 'Call Now' },
            { value: 'sign_up', name: 'Sign Up' }
          ]
        }
      },
      {
        name: 'Headline',
        key: 'headline',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Headline' }
      },
      {
        name: 'Hook',
        key: 'hook',
        validOperations: getFilterTypeOperations('text'),
        input: { type: 'text', placeholder: 'Hook' }
      }
    ]
  }
]