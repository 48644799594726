// Which colors are used for the selected metrics/columns/kpis in the Lens report.
// The first color is used for the first selected metric/column/kpi, the second for the second, etc.

export default [
  '#004094',
  '#3C94F9',
  '#422BAB',
  '#8971EA',
  '#005542',
  '#00B78F',
  '#823C00',
  '#FFAA51'
]