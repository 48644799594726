<template>
  <div class="overflow-x-hidden">
    <div class="w-full overflow-x-auto scroll-container">
      <table>
        <thead>
          <tr class="uppercase">
            <!-- Grouped by column -->
            <th class="sticky-column">
              <div class="group-cell  flex items-center px-2 py-3 h-10 gap-3 ">
                <input
                  type="checkbox"
                  class="checkbox-input"
                  :checked="preventRowAdd"
                  @change="toggleAllRows"
                >
                <BaseText
                  type="heading"
                  size="overline"
                  class="text-text-subdued"
                >
                  {{ groupedBy }}
                </BaseText>
                <BaseText v-if="selectedRows.length > 0" type="heading" size="overline" class="text-text-disabled">({{ selectedRows.length }} selected)</BaseText>
              </div>
            </th>
            <!-- Other selected kpis -->
            <th
              v-for="header in tableKpis"
              :key="header.key"
              :class="{ 'sticky-column': header.is_pinned }"
              :style="header.is_pinned ? { left: `${calculatePinOffset(header)}px` } : ''"
            >
              <div class="p-3 pr-1.5 h-10 flex items-center gap-x-1 justify-between group cell-header">
                <!-- Active pin  -->
                <div class="flex items-center gap-1">
                <div v-if="selectedColumns.includes(header.key)" class="w-2.5 h-2.5 rounded-sm"
                  :style="{ backgroundColor: getColumnColor(header) }"
                />
                <BaseText
                  type="heading"
                  size="overline"
                  class="text-text-subdued text-left truncate flex-shrink"
                  :class="{ 'text-text-muted': header.is_pinned, 'mr-3.5': !selectedColumns.includes(header.key) }"
                >
                  {{ header.key }}
                </BaseText>
              </div>
                <div class="flex items-center column-actions">
                  <!-- Action to pin -->
                  <div
                    class="hover:bg-background-normal text-icon-disabled rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100"
                    :class="{'text-icon-muted opacity-100' : header.is_pinned}"
                    @click="pinColumn(header)"
                  >
                    <PinIcon
                      :class-name="header.is_pinned ? 'text-icon-muted' : 'text-icon-disabled'"
                    />
                  </div>
                  <div
                    class="hover:bg-background-normal text-icon-disabled rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100"
                    :class="{ 'text-icon-muted opacity-100': currentSort?.by === header.key }"
                    :style="currentSort?.order === 'asc' ? 'transform: scale(1, -1)' : ''"
                    @click="sortColumn(header)"
                  >
                    <SortColumnIcon :class="currentSort?.by === header.key ? 'text-icon-muted' : 'text-icon-disabled'" />
                  </div>
                  <div
                    class="hover:bg-background-normal text-icon-disabled  rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100"
                    :class="{ 'text-icon-muted opacity-100': selectedColumns.includes(header.key) }"
                    @click="selectColumn(header)"
                  >
                    <CheckmarkIcon
                      :class="selectedColumns.includes(header.key) ? 'text-icon-muted' : 'text-icon-disabled'"
                    />
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in rows"
            :key="`${row}-${index}`"
          >
            <td
              class="sticky-column table-cell"
              :class="{ 'table-highlight': hoveredCell.row === index }"
            >
              <div class="group-cell grid grid-cols-12 py-1 px-2 gap-x-3 items-center">
                <!-- Left checkbox -->
                <div class="col-span-1">
                  <input
                    :checked="isRowSelected(row.group_by)"
                    class="checkbox-input"
                    type="checkbox"
                    :disabled="!isRowSelected(row.group_by) && preventRowAdd"
                    @change="selectRow(row)"
                  >
                </div>
                <!-- Middle info -->
                <div class="col-span-8 ml-1">
                  <div class="flex items-center flex-nowrap gap-2 overflow-x-hidden min-w-0">
                    <!-- Group image -->
                    <img
                      :src="row.ads?.[0].ad_image_url"
                      class="rounded-md w-7 h-7 flex-shrink-0 bg-cover"
                    >
                    <!-- Group title & ad count -->
                    <div class="flex-grow min-w-0 whitespace-nowrap">
                      <BaseText
                        class="text-text-muted truncate"
                        size="sm"
                      >
                        {{ row.group_by }}
                      </BaseText>
                      <BaseText
                        class="text-text-normal"
                        size="xs"
                      >
                        {{ row.ads.length }} Ads
                      </BaseText>
                    </div>
                  </div>
                </div>
                <!-- CTA actions -->
                <div class="col-span-2">
                  <div class="flex items-center min-w-0">
                    <!-- Add hover effects -->
                    <div class="p-1 cursor-pointer">
                      <AdDetailsAnimatedIcon class="cursor-pointer text-icon-normal" />
                    </div>
                    <div class="p-1 cursor-pointer">
                      <CreativeInsightIcon class="cursor-pointer text-icon-normal" />
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- Selected KPI Columns -->
            <td
              v-for="(kpi, colIndex) in tableKpis"
              :key="`${kpi}-${colIndex}`"
              :ref="`td-${kpi}`"
              class="table-cell "
              :class="{ 'table-highlight': hoveredCell.col === colIndex || hoveredCell.row === index , 'sticky-column': kpi.is_pinned, [getColoredCell(row,kpi)]: kpi.key !== 'spend'}"
              :style="kpi.is_pinned ? { left: `${calculatePinOffset(kpi)}px` } : ''"
              @mouseover="hoveredCell = { col: colIndex, row: index }"
              @mouseleave="hoveredCell = {}"
            >
            <div class="cell p-3 relative ">
                <!-- Handle status & tag cells -->
                <div
                  v-if="kpi.key === 'status' && tableConfig?.showStatus"
                >
                  <StatusCell
                    :data="{ value: { active: row.active_ad_count, inactive: row.inactive_ad_count, paused: row.paused_ad_count } }"
                  />
                </div>
                <TableCell
                  v-else
                  :metric-direction="getMetricLookup?.[kpi.key].metric_direction"
                  :avg-diff="row?.percent_deviation?.[kpi.key]"
                  :cell-type="getMetricLookup?.[kpi.key]?.type"
                  :cell-value="row?.[kpi.key]"
                />
              </div>
            </td>
          </tr>
        </tbody>
        <!-- Table footer (Net results across all groupings) -->
        <tfoot>
          <tr>
            <td class="sticky-column">
              <div class="group-cell  p-3 bg-background-disabled h-full flex gap-3 items-center">
               
                <InformationIcon class="text-icon-disabled" />
                <BaseText
                  size="sm"
                  type="label"
                  class="text-text-muted col-span-10"
                >
                  Net Result
                </BaseText>
              </div>
            </td>
            <td
              v-for="(kpi,kpiIndex) in tableKpis"
              :key="`net-${kpi}-${kpiIndex}`"
              :class="{ 'sticky-column': kpi.is_pinned }"
              :style="kpi.is_pinned ? { left: `${calculatePinOffset(kpi)}px` } : ''"
            >
            <div class="cell p-3 flex items-center gap-3 bg-background-disabled">
                <TableCell
                v-if="kpi !== 'status'"
                  :cell-type="getMetricLookup?.[kpi.key]?.type"
                  :cell-value="netResults?.[kpi.key]"
                />
                <!-- Average -->
                <div class="min-w-0 ">
                  <BaseText
                    class="truncate text-text-muted w-max"
                    size="sm"
                  >
                    <div v-if="kpi === 'status' && tableConfig?.showStatus">
                      {{ netResults.active_ad_count }} Active, {{ netResults.paused_ad_count }} Paused, {{ netResults.inactive_ad_count }} Inactive
                    </div>
                    <div
                      v-else
                      class="flex-nowrap flex items-center"
                    >
                      Avg.&nbsp;
                      <TableCell
                        :cell-type="getMetricLookup?.[kpi.key]?.type"
                        :cell-value="netResults.weighted_averages?.[kpi.key]"
                      />
                    </div>
                  </BaseText>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <TablePagination
      class="mr-12"
      v-if="tablePagination?.totalPages !== 0"
      :showing="rows.length"
      :tablePagination="tablePagination"
      @updatePagination="$event => $emit('paginationChanged', { type: null, value: $event})"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import kpiColors from '../../../utils/lens/selectedMetricColors'

// Table cell components
import TablePagination from './TablePagination.vue'
import TableCell from './cells/TableCell.vue'
import StatusCell from './cells/StatusCell.vue'

// Icons
import AdDetailsAnimatedIcon from '../../globals/Icons/AdDetailsAnimatedIcon.vue'
import CreativeInsightIcon from '../../globals/Icons/CreativeInsightIcon.vue'
import SortColumnIcon from '../../globals/Icons/SortColumnIcon.vue'
import CheckmarkIcon from '../../globals/Icons/CheckmarkIcon.vue'
import PinIcon from '../../globals/Icons/SpyderV2Icons/PinIcon.vue'
import InformationIcon from '../../globals/Icons/InformationIcon.vue'
export default {
  name: 'LensMetricTable',
  components: {
    TablePagination,
    TableCell,
    StatusCell,
    AdDetailsAnimatedIcon,
    CreativeInsightIcon,
    InformationIcon,
    SortColumnIcon,
    CheckmarkIcon,
    PinIcon
  },
  props: {
    includedKpis: {
      type: Array,
      default: () => []
    },
    groupedBy: {
      type: String,
      default: () => 'Ad Name'
    },
    tablePagination: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {}
    },
    selectedColumns: {
      type: Array,
      default: () => []
    },
    selectedRows: {
      type: Array,
      default: () => []
    },
    tableColumns: {
      type: Array,
      default: () => []
    },
    tableConfig: {
      type: Object,
      default: () => {}
    },
    currentSort: {
      type: Object,
      default: () => {}
    },
    maxRowCount: {
      type: Number,
      default: () => 8
    }
  },
  data () {

    const positiveCells  = ['cell-green-lowAvg', 'cell-green-midAvg', 'cell-green-highAvg']
    const negativeCells = ['cell-red-lowAvg', 'cell-red-midAvg', 'cell-red-highAvg']
    
    return {
      positiveCells,
      negativeCells,

      hoveredCell: {}
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getAppliedPreset']),
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    rows () {
      if (this.data?.ad_groups?.length > 0) {
        return this.data.ad_groups
      }
      return []
    },
    netResults () {
      if (this.data?.ad_groups?.length > 0) {
        return { ...this.data }
      }
      return {}
    },
    pinnedColumns(){
      return this.tableColumns.filter((col) => col.is_pinned);
    },
    tableKpis () {
      const showStatus = this.tableConfig?.showStatus

      if (this.pinnedColumns.length > 0) {
        return [
          ...(showStatus ? [{key: 'status'}] : []),
          ...[...this.tableColumns].sort((a, b) => {
            const indexA = this.pinnedColumns.indexOf(a)
            const indexB = this.pinnedColumns.indexOf(b)
            if (indexA === -1 && indexB === -1) return 0
            if (indexA === -1) return 1
            if (indexB === -1) return -1
            return indexA - indexB
          })
        ]
      }

      return [
        ...(showStatus ? [{key:'status'}] : []),
        ...this.tableColumns
      ]
    },
    preventRowAdd(){
      return this.selectedRows?.length === this.maxRowCount
    }
  },
  methods: {
    ...mapActions('LensModule', ['updateLocalPresetField']),
    // memoize the color format for a given value & kpi & color format
    showColoredCell: _.memoize(function (percentDiff, kpi, outlierFormat) {
      const { max, min } = this.data?.summary_stats?.ad_groups?.percent_deviation

      const highestAvg = Math.floor(max[kpi])
      const lowestAvg = Math.floor(min[kpi])

      // Will need to update to handle arbitrary # of columns
      if (kpi === 'status') return;
      const metricDir = this.getMetricLookup[kpi].metric_direction;
      const avgToDivide = metricDir === 'positive' ? highestAvg : lowestAvg;
      
      const cellColors = outlierFormat === 'positive' ? this.positiveCells : this.negativeCells
      const divider = Math.floor(avgToDivide / cellColors.length)
      if (outlierFormat === 'positive' && percentDiff >= 5) {
        const greenIntensity = highestAvg === percentDiff ? cellColors?.length-1 : cellColors.findIndex((_, i) => percentDiff >= i * divider && percentDiff < (i + 1) * divider)
        return greenIntensity !== -1 ? this.positiveCells[greenIntensity] : ''
      }
      else if (outlierFormat === 'negative' && percentDiff <= -5){
        const redIntensity = lowestAvg === percentDiff ? -1 : cellColors.findIndex((_,i) => percentDiff <= ((i+1) * divider && percentDiff) <= i * divider  )
        return redIntensity !== -1 ? cellColors[redIntensity] : ''
      }

      return ''
    }, (arg1, arg2, arg3) => `${arg1}-${arg2}-${arg3}`),
    getColoredCell(row,kpi) {
      const key = kpi.key;
      // TODO @Sam, maybe use memoized func directly, and add dependency callback as floored value to prevent new calcs
      const percentDiff = Math.floor(row.percent_deviation?.[key])
      return this.showColoredCell(percentDiff, key, this.tableConfig?.colorFormat);
    },
    manageArrayAdd (arr, toAdd) {
      const exists = arr.includes(toAdd)
      return exists ? arr.filter((val) => val !== toAdd) : [...arr, toAdd]
    },
    // Column actions
    pinColumn (header) {
      console.log('pinning', header)
      // const newStickyColumns = this.manageArrayAdd(this.pinnedColumns, header)
      const newTableCols = [...this.tableColumns].map(col => {
        if (col.key === header.key) {
          return { ...col, is_pinned: !col.is_pinned };
        }
        return col;
      });
      console.log(newTableCols[0].key, newTableCols[0].is_pinned)
      this.$emit('reportChanged', { type: 'table_columns', value: newTableCols, preventFetch: true })
    },
    sortColumn (column) {
      const colKey = column.key
      const sameKey = this.currentSort?.by === colKey
      const isAsc = this.currentSort?.order === 'asc'

      const newSort = {
        by: colKey,
        order: sameKey ? isAsc ? 'desc' : 'asc' : 'desc'
      }
      // Prevent fetch on report change, as pagination will trigger a requery
      this.$emit('reportChanged', { type: 'sorted_column', value: newSort, preventFetch: true })
      this.$emit('paginationChanged', { type: null, value: {currentPage: 1}})
    },
    selectColumn (column) {
      const colKey = column.key
      const updatedColumns = this.manageArrayAdd(this.selectedColumns, colKey)
      this.$emit('update:selectedColumns', updatedColumns)
    },
    selectRow (row) {
      const rowId = row.group_by;

      const updatedRows = this.selectedRows.some(selectedRow => selectedRow.group_by === rowId)
        ? this.selectedRows.filter(selectedRow => selectedRow.group_by !== rowId)
        : [...this.selectedRows, row];
      this.$emit('update:selectedRows', updatedRows)
    },
    isRowSelected (id) {
      if (!this.selectedRows || this.selectedRows.length == 0) return;
      return this.selectedRows.findIndex((row) => row.group_by === id) !== -1;
    },
    toggleAllRows () {
      if (this.preventRowAdd) {
        console.log('%c CLEARING', 'font-size: 16px; color: green;')
        this.$emit('update:selectedRows', [])
      } else {
        console.log(`%c SETTING ALL ${this.maxRow}`, 'font-size: 16px; color: blue;')
        this.$emit('update:selectedRows', this.rows.slice(0, this.maxRowCount))
      }
    },
    calculatePinOffset (kpi) {
      const key = kpi.key;
      // To incrementally clip the next pinned column
      const index = this.pinnedColumns.findIndex((col) => col.key === key)
      if (index === 0) return 320

      let currentColumns = [...this.pinnedColumns]
      currentColumns = currentColumns.slice(0, index)
      return currentColumns.reduce((acc, column) => {
        const columnRef = this.$refs[`td-${column.key}`]?.[0]
        return acc + Math.ceil(columnRef?.offsetWidth || 0)
      }, 320)
    },
    getColumnColor(header){
      const key = header.key;
      const cIndex = this.selectedColumns.findIndex((col) => col === key);

      return kpiColors[cIndex];
    }
  }
}
</script>

<style scoped>
.scroll-container::-webkit-scrollbar {
  height: 16px; 
}

.scroll-container::-webkit-scrollbar-track {
  background: #ffffff;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(236, 239, 243, 1);
  border-radius: 10px;
  border: 5px solid #ffffff;
  cursor: pointer;
  transition: border-color 150ms ease-in-out, border-width 150ms ease-in-out;  
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(223, 225, 231, 1);
  border-width: 4px;
}

.scroll-container {
  transition: all 150ms;
  scroll-behavior: smooth;
}

/* Table Styles */
table {
  border-collapse: separate;
  border-spacing: 0.5px;
}

th,
td {
  background-color: white;
  border: none;
  margin: 0;
  padding: 0px;
}

/* Border Styles (rounded corners & bordered cells) */
table tr th:first-child .group-cell {
  border-top-left-radius: 6px;
  border: 1px solid #ECEFF3;
}

table tr th:last-child .cell-header:last-child {
  border-right: 1px solid #ECEFF3;
  border-top: 1px solid #ECEFF3;
  border-bottom: 1px solid #ECEFF3;
  border-top-right-radius: 6px;
}

table th:not(:first-child):not(:last-child) .cell-header {
  border-right: 1px solid #ECEFF3;
  border-top: 1px solid #ECEFF3;
  border-bottom: 1px solid #ECEFF3;
}

table tr td .group-cell,
table tr td .cell {
  border-right: 1px solid #ECEFF3;
  border-bottom: 1px solid #ECEFF3;
} 

table tr td:not(first-child) .group-cell {
  border-left: 1px solid #eceff3;
} 


table tfoot tr td:last-child .cell {
  border-bottom-right-radius: 6px;
}

table tfoot tr td .group-cell {
  border-bottom-left-radius: 6px;
}


.table-highlight {
  background-color: #fcfcfc;
}

.table-cell {
  transition: colors 50ms ease-in-out;
}


.cell {
  /* Who knows why i added this, commented out for now */
  /* max-height: 40px; */
  /* height: 100%; */
  min-width: 132px;
  max-width: 240px;
}




/* Base cell highlight style */
.cell-highlight{
  position: absolute;
  top: -1px;
  left: 0px;
  height: calc(100% + 1px);
  width: calc(100% + 1px);
  z-index: 2;
  pointer-events: none;
}


.cell-green-lowAvg{
  background-color: #EFFEFA;
}

.cell-green-midAvg {
  background-color: #D8F3EE;
}

.cell-green-highAvg {
  background-color: #B9E9E0;
}

.cell-red-lowAvg{
  background-color: #EFFEFA;
}

.cell-red-midAvg{
  background-color: #FDD9E0;
}

.cell-red-highAvg{
  background-color: #F9C8D2;
}

.group-cell {
  width: 320px;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 3;
}

.column-actions>div {
  transition: opacity 150ms ease-in-out;
}

/*
  Checkbox Styles
*/
.checkbox-input {
  box-shadow: 0px 0px 0px 1px #09194821, 0px 1px 2px 0px #09194821;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
}

.checkbox-input:disabled {
  background-color: #ECEFF3;
  cursor: not-allowed;
}

.checkbox-input:checked {
  background-color: black;
}
</style>
