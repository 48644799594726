<template>
  <div class="w-full flex items-center p-3 border-b border-border-normal">
    <div class="relative text-text-normal text-center mr-2 flex-shrink-0" style="width: 40px;">
      <transition name="clause-label-swap">
        <BaseText v-if="isFirstCondition" type="body" size="sm" key="where"> where </BaseText>
        <BaseText v-else type="body" size="sm" key="or"> or </BaseText>
      </transition>
    </div>
    <!-- Query Builder Options -->
    <div class="flex-grow flex items-center rounded-md border border-border-normal mr-3">
      <!-- Property Selector -->
      <div class="relative border-r border-border-normal" v-on-clickaway="() => {closeDropdown('property')}">
        <button class="flex items-center gap-0.5 pl-2.5 py-1 pr-1 transition-colors rounded-l-md"
        :class="{ 'bg-neutral-25': showDropdown.property }"
        @click="toggleDropdown('property')">
          <BaseText type="label" size="sm" class="text-text-muted whitespace-nowrap">
            {{ selectedPropertyName }}
          </BaseText>
          <div class="flex-shrink-0 transition-transform"
          :style="{ transform: showDropdown.property ? 'scaleY(-1)' : 'scaleY(1)' }">
            <ChevronIcon class="text-icon-normal" />
          </div>
        </button>
        <!-- Property Select Dropdown -->
        <transition>
          <div v-if="showDropdown.property" class="property-select-dropdown">
            <FilterSelectDropdown 
              @filterOptionSelected="selectProperty"
              @close="closeDropdown('property')"
            />
          </div>
        </transition>
      </div>
      <!-- Operation Selector -->
      <div class="relative" v-on-clickaway="() => {closeDropdown('operation')}">
        <button class="flex items-center gap-0.5 pl-2.5 py-1 pr-1 transition-colors"
        :class="{ 'bg-neutral-25': showDropdown.operation }"
        @click="toggleDropdown('operation')">
          <BaseText type="label" size="sm" class="text-text-muted whitespace-nowrap">
            {{ selectedOperationName }}
          </BaseText>
          <div class="flex-shrink-0 transition-transform"
          :style="{ transform: showDropdown.operation ? 'scaleY(-1)' : 'scaleY(1)' }">
            <ChevronIcon class="text-icon-normal" />
          </div>
        </button>
        <!-- Operation Select Dropdown -->
        <transition>
          <div v-if="showDropdown.operation" class="operation-select-dropdown flex flex-col p-1">
            <button v-for="(operation, index) in validOperations" :key="`operation-${index}-${filterCondition.renderKey}`"
            class="operation w-full flex items-center gap-2 p-1.5 rounded-md transition-colors duration-100 
            text-neutral-alpha-650 hover:bg-neutral-alpha-50 hover:text-white min-w-0 box-border"
            @click="selectOperation(operation)">
              <component :is="operation.icon" />
              <BaseText type="body" size="sm" class="whitespace-nowrap">
                {{ operation.name }}
              </BaseText>
              <div class="w-5 h-5 ml-auto">
                <NewCheckmarkIcon v-if="selectedOperationName === operation.name" />
              </div>
            </button>
          </div>
        </transition>
      </div>
      <!-- Value Input -->
      <div class="flex-grow border-l border-border-normal">
        <ConditionValueInput 
          :value="queryValue"
          :type="queryInputConfig.type"
          :placeholder="queryInputConfig.placeholder"
          :dropdownOptions="queryInputConfig.options || []"
          :showDropdown="showDropdown.value"
          @updateValue="updateConditionValue"
          @toggleDropdown="toggleDropdown('value')"
          @closeDropdown="closeDropdown('value')"
        />
      </div>
    </div>
    <!-- Extra Buttons -->
    <div class="ml-auto flex items-center gap-0.5">
      <button class="p-1 rounded-md text-icon-normal transition-colors hover:bg-neutral-25 hover:text-icon-muted">
        <CopyIcon />
      </button>
      <button class="p-1 rounded-md text-icon-normal transition-colors hover:bg-neutral-25 hover:text-icon-muted"
      @click="$emit('removeCondition')">
        <DeleteIcon />
      </button>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import FilterSelectDropdown from './FilterSelectDropdown.vue'
import ConditionValueInput from './ConditionValueInput.vue'

// Icons
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'
import CopyIcon from '../../globals/Icons/StaticCopyIcon.vue'
import DeleteIcon from '../../globals/Icons/DeleteIcon.vue'
import NewCheckmarkIcon from '../../globals/Icons/NewCheckmarkIcon.vue'

export default {
  name: 'QueryCondition',
  mixins: [clickaway],
  components: {
    FilterSelectDropdown,
    ConditionValueInput,
    ChevronIcon,
    CopyIcon,
    DeleteIcon,
    NewCheckmarkIcon
  },
  props: {
    filterCondition: {
      type: Object,
      required: true
    },
    isFirstCondition: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showDropdown: {
        property: false,
        operation: false,
        value: false
      }
    }
  },

  computed: {
    selectedPropertyName () {
      return this.filterCondition.property?.name || 'Select'
    },
    selectedOperationName () {
      return this.filterCondition.operation?.name || 'Select'
    },
    validOperations () {
      return this.filterCondition.property?.validOperations || []
    },
    queryValue () {
      return this.filterCondition.value || ''
    },
    queryInputConfig () {
      return this.filterCondition.property?.input 
        || { type: 'text', placeholder: '' }
    }
  },
  methods: {
    selectProperty (property) {
      this.$emit('selectProperty', property)
      this.closeDropdown('property')
    },
    selectOperation (operation) {
      this.$emit('selectOperation', operation)
      this.closeDropdown('operation')
    },
    updateConditionValue (value) {
      this.$emit('updateConditionValue', value)
    },
    // UI Methods
    toggleDropdown (dropdown) {
      this.showDropdown[dropdown] = !this.showDropdown[dropdown]
      Object.keys(this.showDropdown).forEach(key => {
        if (key !== dropdown) {
          this.showDropdown[key] = false
        }
      })
    },
    closeDropdown (dropdown) {
      this.showDropdown[dropdown] = false
    }
  }
}
</script>


<style scoped>
.property-select-dropdown {
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.operation-select-dropdown {
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background-color: rgba(6, 7, 16, 0.92);
  backdrop-filter: blur(40px);
  z-index: 100;
}
.operation {
  width: auto; /* fallback for calc-size */
  /* width: calc-size(auto, round(up, size, 2px)); */
}

/* ========= Vue <transition> classes ========= */
.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}

.clause-label-swap-enter-active {
  transition: opacity 175ms ease-in-out;
}
.clause-label-swap-leave-active {
  transition: opacity 175ms ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
}
.clause-label-swap-enter-from, .clause-label-swap-enter, .clause-label-swap-leave-to {
  opacity: 0;
}
.clause-label-swap-enter-to, .clause-label-swap-leave-from {
  opacity: 1;
}
</style>
