<template>
  <div class="w-max relative">
    <BaseText
      size="sm"
      class="text-text-muted relative table-cell-value"
    >
      {{ formatValue(cellValue) }}
    </BaseText>
    <!-- Tooltip -->
    <div
      v-if="avgDiff !== undefined"
      class="transform px-1.5 py-1 rounded-md absolute tooltip-container w-max"
    >
      <BaseText
        size="xs"
        class="flex items-center gap-1"
      >
        <span :class="getDiffClass()">{{ getFormatAvg() }}</span><span class="text-neutral-alpha-800">compared to the avg. </span>
      </BaseText>

      <!-- Arrow -->
      <svg
        class="absolute text-black h-2 w-full left-0 top-full"
        x="0px"
        y="0px"
        viewBox="0 0 255 255"
        xml:space="preserve"
      ><polygon
        class="fill-current"
        points="0,0 127.5,127.5 255,0"
      /></svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableCell',
  props: {
    cellType: {
      type: String
    },
    cellValue: {
      type: [String, Number]
    },
    avgDiff: {
      type: [Number, undefined],
      default: () => undefined
    },
    metricDirection: {
      type: String,
      default: () => 'positive'
    }
  },
  methods: {
    getFormatAvg () {
      const value = this.avgDiff
      const prefix = this.avgDiff < 0 ? '' : '+'

      return `${prefix}${Math.floor(value)}%`
    },
    getDiffClass () {
      const value = this.avgDiff

      const isPositive = this.metricDirection === 'positive'
      const isGreen = isPositive ? value > 0 : value >= 0
      const colorClass = isGreen ? 'text-green-500' : 'text-red-500'
      return colorClass
    },
    formatValue () {
      if (this.cellType === 'currency') {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(this.cellValue)
      } else if (this.cellType === 'percent') {
        return `${this.cellValue?.toFixed(2)}%`
      } else {
        return this.cellValue?.toFixed(2)
      }
    }
  }
}
</script>

<style scoped>
.tooltip-container{
  background: #24252D;
  bottom: calc(100% + 4px);
  opacity: 0;
  z-index: 999;
  transition: all 150ms;
  pointer-events:none;

  left: 50%;
  transform: translateX(-50%) translateY(4px);
}

.table-cell-value:hover + .tooltip-container {
  transform: translateX(-50%) translateY(-4px) !important;
  opacity: 1 !important;
}
</style>
