import PerformanceMetricsIcon from '../../../components/globals/Icons/LensIcons/filters/PerformanceMetricsIcon.vue'

import store from '../../../store'
import getFilterTypeOperations from './filterTypeOperations'

const getMetricInputPlaceholder = (type) => {
  switch (type) {
    case 'number': return 'Value'
    case 'currency': return 'Amount'
    case 'percent': return 'Rate'
    default: return 'Value'
  }
}

const getMetricOptions = (parentGroup) => {
  const metrics = store.getters['MetricsModule/getMetrics']
  const groupMetrics = metrics?.[parentGroup]?.metrics
  if (!groupMetrics) return []

  return groupMetrics.map(metric => ({
    name: metric.name,
    key: metric.key,
    validOperations: getFilterTypeOperations(metric.type),
    input: { type: metric.type, placeholder: getMetricInputPlaceholder(metric.type) }
  }))
}

export default function dynamicFilterOptions() {

  const filterCategories = [
    {
      categoryName: 'Performance Metrics',
      icon: PerformanceMetricsIcon,
      options: getMetricOptions('performance')
    }
    // TODO: Extend with additional metric categories
  ]

  return filterCategories
}