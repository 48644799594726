<template>
  <div class="w-full flex gap-1.5" style="height: 360px">
    <!-- Left Axis Labels -->
    <div v-show="showLeftAxisLabels" class="h-full flex flex-col pb-10 pl-2 pr-1 pt-0.5">
      <!-- Metric Name -->
      <BaseText type="label" size="xs" class="text-text-muted capitalize mb-2">
        {{ selectedKpis[0] }}
      </BaseText>
      <!-- Axis Labels -->
      <div class="flex-grow flex flex-col justify-between">
        <div v-for="(label, index) in [...leftAxisLabels].reverse()" :key="`left-axis-label-${index}`">
          <BaseText type="body" size="xs" class="text-text-normal">
            {{ label }}
          </BaseText>
        </div>
      </div>
    </div>
    <!-- Bar Graphs -->
    <div v-if="selectedKpis.length" class="flex-grow grid gap-x-1" :style="{ gridTemplateColumns: `repeat(${dataItems.length}, 1fr)` }">
      <BarGraphCard 
        v-for="(item, index) in dataItems" :key="`bar-graph-${item.group_by}`"
        :data="item" 
        :selected-kpis="selectedKpis"
        :axis-scales="axisScales"
        :num-plot-lines="numPlotLines"
        :card-index="index"
        :total-cards="dataItems.length"
      />
    </div>
    <!-- Right Axis Labels -->
    <div v-show="showRightAxisLabels" class="h-full flex flex-col pb-10 pr-2 pl-1 pt-0.5 text-right">
      <!-- Metric Name -->
      <BaseText type="label" size="xs" class="text-text-muted capitalize mb-2">
        {{ selectedKpis[1] }}
      </BaseText>
      <!-- Axis Labels -->
      <div class="flex-grow flex flex-col justify-between">
        <div v-for="(label, index) in [...rightAxisLabels].reverse()" :key="`right-axis-label-${index}`">
        <BaseText type="body" size="xs" class="text-text-normal">
            {{ label }}
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { scaleLinear } from 'd3-scale'
import formatLabelString from '../../../../utils/lens/formatGraphLabelString'

import BarGraphCard from './BarGraphCard.vue'

export default {
  name: 'LensBarGraphs',
  components: {
    BarGraphCard
  },
  props: {
    dataItems: {
      type: Array,
      default: () => []
    },
    selectedKpis: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      axisScales: [],
      leftAxisLabels: [],
      rightAxisLabels: [],
      numPlotLines: 0
    }
  },
  watch: {
    dataItems: {
      handler () {
        this.initializeAxisInformation()
      },
      deep: true
    },
    selectedKpis: {
      handler () {
        this.initializeAxisInformation()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    showLeftAxisLabels () {
      return this.selectedKpis.length >= 1 && this.selectedKpis.length <= 2
    },
    showRightAxisLabels () {
      return this.selectedKpis.length === 2
    }
  },
  methods: {
    initializeAxisInformation () {
      if (!this.dataItems.length || !this.selectedKpis.length) {
        this.axisScales = []
        this.leftAxisLabels = []
        this.rightAxisLabels = []
        return
      }
      this.computeAxisScales()
      this.computeLeftAxisLabels()
      this.computeRightAxisLabels()
    },
    computeAxisScales () {
      const scales = []
      if (this.dataItems.length) {
        this.selectedKpis.forEach(kpi => {
          scales.push(createKpiAxisScale(this.dataItems.map(item => item[kpi])))
        })
      }
      this.axisScales = scales
    },
    computeLeftAxisLabels () {
      const scale = this.axisScales[0]
      let ticks = scale.ticks(10)
      const reduceTicks = (ticks) => {
        // Ensures that the number of ticks is less than or equal to 10
        let tickValues = [...ticks]
        if (ticks.length % 2 === 0) {
          const newMax = ticks[ticks.length - 1] + ticks[1]
          tickValues.push(newMax)
          scale.domain([0, newMax])
        }
        while (tickValues.length > 10) {
          tickValues = tickValues.filter((_, index) => index % 2 === 0)
        }
        return tickValues
      }
      ticks = reduceTicks(ticks)
      this.leftAxisLabels = ticks.map(tick => this.formatAxisLabelString(tick, 0))
      this.numPlotLines = ticks.length
    },
    computeRightAxisLabels () {
      if (this.selectedKpis.length < 2) {
        this.rightAxisLabels = []
        return
      }
      const numLabels = this.leftAxisLabels.length
      const scale = this.axisScales[1]
      const [_, d1] = scale.domain()
      const maxValue = d1

      const step = maxValue / (numLabels - 1)
      const ticks = Array.from({ length: numLabels }, (_, index) => index * step)
      this.rightAxisLabels = ticks.map(tick => this.formatAxisLabelString(tick, 1))
    },
    formatAxisLabelString (value, selectedKpiIndex) {
      const kpi = this.selectedKpis[selectedKpiIndex]
      const type = this.getMetricLookup?.[kpi]?.type
      return formatLabelString(value, type)
    }
  }
}

const createKpiAxisScale = (values) => {
  let maxValue = Math.max(...values)
  if (maxValue === 0) maxValue = 1
  const scale = scaleLinear()
    .domain([0, maxValue])
    .nice()
  return scale
}
</script>